<template>
  <div>
    <div style="width:300px; margin: 0 auto">
      <div
        class="qr-code-wrap"
        style="margin: 0 auto"
      >
        <QRCanvas
          class="qr-code"
          :options="getQrOptions"
          @updated="onQrUpdated"
        />
      </div>
      <div
        class="dx-field"
        style="text-align:center;margin:20px auto 0 auto;width:200px;"
      >
        <v-row>
          <v-col
            cols="6"
          >
            <input
              v-model="printQrCount"
              type="number"
              style="border:1px solid white;width:80px; height:38px;"
            >
          </v-col>
          <v-col
            cols="6"
          >
            <DxButton
              text="프린트"
              type="danger"
              width="110"
              @click="onPrintQrcode($event)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="margin-top:10px;">
      <dx-data-grid
        :data-source="zebraInfo"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :height="200"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :auto-navigate-to-focused-row="false"
        :focused-row-key.sync="focusedRowKey"
        key-expr="seqId"
        @focused-row-changed="onSelectionChangedZebra"
      >
        <DxColumn
          :allow-editing="true"
          caption="지역"
          data-field="지역"
          :visible="true"
          sort-order="asc"
        />
        <DxColumn
          :allow-editing="false"
          caption="프린터명"
          data-field="프린터명"
          :visible="true"
          sort-order="asc"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            column="지역"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../share/AppLib'
import DxDataGrid, {
  DxScrolling, DxTotalItem,
  DxSummary, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'
import { DxButton } from 'devextreme-vue'
// import AesCrypto from '../../share/service/AesCrypto'
// eslint-disable-next-line no-unused-vars
import HttpService from '../share/service/HttpService'
const { QRCanvas } = require('qrcanvas-vue')

export default {
  name: 'Login',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSummary,
    DxTotalItem,
    DxScrolling,
    QRCanvas,
    DxButton
  },
  props: {
    qrOptions: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      popupQrcode: false,
      printQrCount: 10,
      // zebra info
      zebraInfo: null,
      selectedZebraInfo: null,
      focusedRowKey: null
    }
  },
  computed: {
    getQrOptions () {
      return this.qrOptions
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.refreshZebraInfo()
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    refreshZebraInfo () {
      this.$_sp.runNoEncodeFindProc('spFindAllPrinterList', {})
        .then((data) => {
          this.zebraInfo = this.$_sp.MakeModel(data)
          if (this.zebraInfo.length === 1) {
            this.focusedRowKey = this.zebraInfo[0].seqId
          }
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // qr code popup *********
    onPrintQrcode (e) {
      if (this.selectedZebraInfo === null) {
        this.$snotify.info('출혁 할 프린터 정보를 선택하여 주세요.')
        return
      }
      console.log(this.printQrCount)
      this.$snotify.confirm(`${this.selectedZebraInfo.프린터명}로 (${this.printQrCount}매) 프린팅 하시겠습니까?`, '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '프린트',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.printQrcode(this.qrOptions.title, this.printQrCount)
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onQrUpdated (e) {
      this.$Q('.qr-code-wrap').css('width', e.width + 'px')
    },
    async printQrcode (pCode, count) {
      this.Loading = true
      let pInfo = null
      await this.$_sp.runNoEncodeFindProc('spFindAllProductInfoJoinCompany', { 품번: pCode })
        .then((data) => {
          pInfo = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      const 창고관리 = this.$_.findWhere(this.$store.getters.getSysParam, { key: '창고관리' }).value
      if (pInfo === null || pInfo.length === 0) {
        this.isLoading = false
        this.$snotify.error(`${pCode} 정보를 검색할 수 없습니다.`)
        return
      } else if (pInfo[0].품목유형 === ConstDef.구매품 && pInfo[0].자재유형 !== ConstDef.외주직납품 &&
        창고관리 === 'false' && pInfo[0].자재유형 !== ConstDef.외주품 && pInfo[0].자재유형 !== ConstDef.사급품) {
        this.isLoading = false
        this.$snotify.error('일반구매품 부품식별표는 출력할 필요가 없습니다.')
        return
      }
      const param = {
        품번: pCode,
        count: count,
        createid: this.userInfo.userName,
        createtime: AppLib.getNow()
      }
      await this.$_sp.runUpdateProc('spCreateGoodsQrCodeSerialNo', [param])
        .then((data) => {
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.Loading === false) {
        this.$snotify.error('부품식별표 발행 요청 실패하였습니다.')
        return
      }
      delete param.count
      let qrList = null
      await this.$_sp.runNoEncodeFindProc('spFindAllInputQrcode', param)
        .then((data) => {
          qrList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.Loading === false) {
        this.$snotify.error('부품식별표 생성 요청 실패하였습니다.')
        return
      }
      // TODO QRCODE Print 요청
      const printList = []
      let zpl = null
      const year = AppLib.getToday().substring(1, 4)
      // 사이즈변경시 unremrk
      for (let i = 0; i < qrList.length; i++) {
        // const base64 = AppLib.getBase64SameLength(`${qrList[i].품번}/${qrList[i].seqId}`)
        // zpl = await this.mkaeZpl(pInfo[0], base64, `${qrList[i].seqId}`)
        const base64 = AppLib.getBase64SameLength(`${qrList[i].품번}/${qrList[i].seqId}${year}`)
        zpl = await this.mkaeZpl(pInfo[0], base64, `${qrList[i].seqId}${year}`, this.selectedZebraInfo.zpl)
        console.log(zpl)
        const temp = {
          지역: this.selectedZebraInfo.지역,
          프린터명: this.selectedZebraInfo.프린터명,
          프린터주소: this.selectedZebraInfo.프린터주소,
          포워딩포트: this.selectedZebraInfo.포워딩포트,
          qrCode: base64,
          문자: `${qrList[i].품번}/${qrList[i].seqId}`,
          문자2: `${qrList[i].품명}`,
          장수: 1,
          완료: !ConstDef.QR_PRINT,
          zpl: zpl,
          사용유무: 0,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName
        }
        printList.push(temp)
      }
      await this.$_sp.runInsertSqlProc('프린팅대기', null, printList)
        .then((data) => {
          this.$snotify.info('부품식별표 프린팅 요청하였습니다.')
          HttpService.reqPost(JSON.stringify({ printName: this.selectedZebraInfo.프린터명 }), '/qrCodePrinting')
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      console.log(printList)
      this.Loading = false
    },
    async mkaeZpl (pInfo, qrCode, seqId, zpl) {
      let carType = null
      if (pInfo.차종 === null) {
        carType = await this.searchCarType(pInfo.품번)
      } else {
        carType = pInfo.차종
      }
      // zpl = this.$_.findWhere(this.$store.getters.getSysParam, { key: 'ZPL_TYPE1' }).value
      zpl = AppLib.replaceAll(zpl, '{{PROCESSING_NAME}}', pInfo.설명)
      zpl = AppLib.replaceAll(zpl, '{{GOODS_NO}}', pInfo.품번)
      zpl = AppLib.replaceAll(zpl, '{{GOODS_NAME}}', pInfo.품명)
      zpl = AppLib.replaceAll(zpl, '{{LOT_NO}}', seqId)
      zpl = AppLib.replaceAll(zpl, '{{QRCODE}}', qrCode)
      zpl = AppLib.replaceAll(zpl, '{{CAR_TYPE}}', carType)
      if (qrCode.length <= 24) {
        zpl = AppLib.replaceAll(zpl, '{{QR_SIZE}}', 7)
      } else {
        zpl = AppLib.replaceAll(zpl, '{{QR_SIZE}}', 6)
      }
      if (zpl !== null) {
        if (pInfo.품명.length < 20) {
          zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '90')
        } else if (pInfo.품명.length < 29) {
          zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '65')
        } else {
          zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '45')
        }
      }
      // if (zpl !== null) {
      //   if (pInfo.품명.length < 20) {
      //     zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '43')
      //   } else if (pInfo.품명.length < 27) {
      //     zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '35')
      //   } else if (pInfo.품명.length < 33) {
      //     zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '27')
      //   } else {
      //     zpl = AppLib.replaceAll(zpl, '{{GOODS_NMAE_FONT}}', '25')
      //   }
      // }
      return zpl
    },
    async searchCarType (pCode) {
      let carType = ''
      await this.$_sp.runNoEncodeFindProc('spFindAllCarTypeByPcode', { 품번: pCode })
        .then((data) => {
          const result = this.$_sp.MakeModel(data)
          for (let i = 0; i < result.length; i++) {
            carType += result[i].차종
            if (i < result.length - 1) {
              carType += '/'
            }
          }
        })

      return carType
    },
    // zebran grid
    onSelectionChangedZebra (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedZebraInfo = e.row.data
      }
    }

  }
}
</script>

<style lang="scss">
.ff{
  text-align: center;
}
</style>
