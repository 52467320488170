<template>
  <v-container>
    <dx-data-grid
      ref="refQrcodeGrid"
      :data-source="goodsInfoList"
      :show-borders="true"
      :show-row-lines="true"
      :show-column-lines="true"
      :hover-state-enabled="true"
      :allow-column-resizing="true"
      :height="gridHeight"
      column-resizing-mode="widget"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      @row-dbl-click="onRowDblClick"
      @toolbar-preparing="onToolbarPreparing"
    >
      <DxSearchPanel
        :visible="true"
        :width="130"
        placeholder="검색..."
      />
      <DxFilterRow
        :visible="true"
        apply-filter="auto"
      />
      <DxEditing
        :allow-deleting="false"
        :allow-updating="false"
        :allow-adding="false"
        :use-icons="true"
        mode="row"
      />
      <DxColumn
        v-if="type==='S0100' && $store.getters.getGroupName !== null"
        :allow-editing="false"
        :caption="$store.getters.getGroupName"
        data-field="차종"
        sort-order="asc"
        :min-width="62"
      />
      <DxColumn
        v-if="type!=='S0100'"
        :allow-editing="false"
        caption="차종"
        data-field="상위차종"
        sort-order="asc"
        :min-width="62"
      />
      <DxColumn
        :allow-editing="false"
        caption="품번"
        data-field="품번"
        :min-width="140"
      />
      <DxColumn
        v-if="isPruchase"
        caption="유형"
        data-field="자재유형"
        :min-width="74"
      >
        <DxLookup
          :data-source="meterialTypeLookup"
          display-expr="코드명"
          value-expr="코드"
        />
      </DxColumn>
      <DxColumn
        v-if="!isPruchase"
        caption="공정"
        data-field="공정유형"
        :min-width="74"
      >
        <DxLookup
          :data-source="processingTypeLookup"
          display-expr="코드명"
          value-expr="코드"
        />
      </DxColumn>
      <DxColumn
        :allow-editing="false"
        data-field=""
        cell-template="cellTemplate"
        caption="품번QR"
        alignment="center"
        :allow-sorting="false"
        :min-width="70"
      />
      <DxColumn
        :allow-editing="false"
        caption="품명"
        data-field="품명"
        :min-width="140"
      />
      <dx-paging :enabled="false" />
      <dx-scrolling
        mode="virtual"
      />
      <dx-sorting mode="multiple" />
      <template #cellTemplate="{ data }">
        <QRCanvas
          :options="getQrOption(data)"
        />
      </template>
    </dx-data-grid>
    <DxPopupGen
      v-if="qrOptions !== null"
      class="qrcode-popup-wrap"
      :visible.sync="popupQrcode"
      :drag-enabled="true"
      :close-on-outside-click="false"
      :show-title="true"
      :width="350"
      :height="500"
      :show-close-button="true"
      :title="qrCodePopupTitle"
    >
      <SerailNoQrPrintComponent :qr-options="qrOptions" />
    </DxPopupGen>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </v-container>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxLookup, DxScrolling, DxColumn, DxPaging, DxSorting, DxEditing, DxSearchPanel, DxFilterRow } from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import { DxPopup as DxPopupGen } from 'devextreme-vue/popup'
import SerailNoQrPrintComponent from '../../components/SerailNoQrPrintComponent'
const { QRCanvas } = require('qrcanvas-vue')

export default {
  name: 'MainPage',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxEditing,
    DxSearchPanel,
    DxFilterRow,
    QRCanvas,
    DxPopupGen,
    DxLookup,
    SerailNoQrPrintComponent
  },
  props: {
    type: {
      type: String,
      default: '0'
    }
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      goodsInfoList: [],
      gridHeight: null,
      meterialTypeLookup: null,
      processingTypeLookup: null,
      massCar: true,
      // popup
      qrOptions: null,
      popupQrcode: false,
      qrCodePopupTitle: null
    }
  },
  computed: {
    isPruchase () {
      return this.type === ConstDef.구매품
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.processingTypeLookup = this.$store.getters.getBaseDataByType(ConstDef.공정유형)
    this.meterialTypeLookup = this.$store.getters.getBaseDataByType(ConstDef.자재유형)
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 90) - 30
    this.$Q('#p-code').focus()
    this.refreshProduct()
  },
  updated () {
  },
  methods: {
    getQrOption (pCode) {
      return { data: AppLib.getBase64SameLength(pCode.values[0]), cellSize: 2 }
    },
    onRowDblClick (e) {
      console.log(e)
      this.qrCodePopupTitle = e.data.품번
      this.popupQrcode = true
      this.qrOptions = { title: e.data.품번, data: AppLib.getBase64SameLength(e.data.품번), cellSize: 5 }
      console.log(this.qrOptions.data.length)
    },
    onToolbarPreparing (e) {
      if (this.type !== ConstDef.완제품) {
        return
      }
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxCheckBox',
          options: {
            value: this.massCar,
            text: '양산차종',
            onValueChanged: (args) => {
              this.massCar = args.value
              this.refreshProduct()
            }
          }
        }
      )
    },
    onQrUpdated (e) {
      this.$Q('.qr-code-wrap').css('width', e.width + 'px')
    },
    refreshProduct () {
      this.isLoading = true
      let callProc = null
      if (this.type === ConstDef.반제품 || this.type === ConstDef.다공정품) {
        callProc = this.$_sp.runNoEncodeFindProc('spFindAllSemiProductWithCarType', {})
      } else {
        if (this.type === ConstDef.완제품) {
          callProc = this.$_sp.runNoEncodeFindProc('spFindAllProductByMassCar', { is양산차종: this.massCar })
        } else {
          callProc = this.$_sp.runNoEncodeFindProc('spFindAllPurchaseWithCarType', {})
        }
      }
      callProc
        .then((data) => {
          this.isLoading = false
          this.goodsInfoList = this.$_sp.MakeModel(data)
          console.log(this.goodsInfoList)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .data-grid {
    margin-top:0px;
  }
  .qrcode-popup-wrap {
    .dx-popup-content {
      background-color: rgb(165, 164, 164)!important;
    }
  }
</style>
