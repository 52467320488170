<template>
  <div>
    <DxTabs
      :data-source="tabs"
      :selected-index.sync="selectedIndex"
      :focus-state-enabled="false"
    />
    <PurchaseQrcode v-if="selectedIndex === 0" />
    <ProductQrcode v-if="selectedIndex === 1" />
    <WipQrcode v-if="selectedIndex === 2" />
  </div>
</template>

<script>
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxTabs from 'devextreme-vue/tabs'
import PurchaseQrcode from './PurchaseQrcode'
import ProductQrcode from './ProductQrcode'
import WipQrcode from './WipQrcode'

export default {
  name: 'BomTab',
  components: {
    DxTabs,
    PurchaseQrcode,
    ProductQrcode,
    WipQrcode
  },
  data: function () {
    return {
      selectedIndex: 0,
      tabs: [
        {
          id: 0,
          text: '구매품'
        },
        {
          id: 1,
          text: '완제품'
        },
        {
          id: 2,
          text: '반제품'
        }
      ]
    }
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    const user = this.$store.getters.getUserInfo
    console.log(user)
    if (user === undefined || user === null) {
      this.$router.push('/')
      return
    }
    this.$snotify.info('QR코드 더블클릭시 자재 입고용 QR코드를 프린팅 할 수 있습니다.')
  },
  mounted () {

  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
  .notactive {
    display: none
  }
  .dx-tab-text{
    .dx-icon {
      margin-top: -8px!important;
    }
  }
</style>
