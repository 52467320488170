<template>
  <div class="sub-content">
    <GoodsQrcodeListComponent type="S0104" />
  </div>
</template>
<script>
import GoodsQrcodeListComponent from './GoodsQrcodeListComponent'

export default {
  name: 'MainPage',
  components: {
    GoodsQrcodeListComponent
  },
  data: function () {
    return {
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {

  },
  updated () {
  },
  methods: {

  }
}
</script>

<style lang="scss">
</style>
